import React from "react";
import { Router } from "@reach/router";
import Layout from "../../components/Layout";
// import Profile from "../components/Profile";
// import Details from "../components/Details";
import Test from "../../components/test";
import Test2 from "../../components/test2";

const Index = () => {
  return (
    <Router basepath="/app">
      {/* <Profile path="/profile" />
        <Details path="/details" /> */}
      <Test path="/" />
      <Test2 path="/test" />
    </Router>
  );
};
export default Index;
